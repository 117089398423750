.btn.btn-trilha {
  border-radius: 100% !important;
  padding: 0px !important;

  width: 3.875rem;
  height: 3.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--light);
  font-size: 1.756rem;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
}

.btn.btn-trilha:hover {
  color: var(--light);
}

.btn.btn-trilha.btn-trilha--content .btn-trilha-content {
  border-radius: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  height: 83%;
  background-color: white;
  color: var(--dark);
}

.trilha-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.trilha-container .card-title {
  width: 100%;
}

.trilha-container .trilha-block {
  display: flex;
  flex-flow: column wrap;
  flex-grow: 0;
  flex-wrap: 1;
  align-items: center;
  justify-content: flex-start;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

@media screen and (min-width: 992px) {
  .trilha-container {
    justify-content: flex-start;
  }
}

.btn-trilha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.btn-trilha.btn-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 24px;
  padding: 10px 16px;
}

.trilha-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
